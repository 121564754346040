/**
 * Testimonials Block JavaScript
 *
 * @package Davis Woolfe
 * @subpackage Scripts
 * @since 1.0
 * @version 1.0
 */

window.thSlider = {};
window.thSlider.options = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 651,
    slidesToShow: 1,
    autoplay: true,
    adaptiveHeight: false,
    autoplaySpeed: 2500,
    fade: true,
}

$(document).ready(() => {
    $('.th-slider').not('.slick-intialized').slick(window.thSlider.options);
});