/**
 * The entry point for the app's JavaScript
 *
 * @package Davis Woolfe
 * @subpackage Scripts
 * @since 1.0
 * @version 1.0
 */

/* Required */

/* jQuery */
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

/**
 * What input (Foundation dependency)
 * https://www.npmjs.com/package/what-input
 */
import 'what-input';

/**
 * Foundation
 * https://www.npmjs.com/package/foundation-sites
 */
import 'foundation-sites';

/* Slick carousel */
import slick from 'slick-carousel';

/* Header JS */
require('./header');
require('./testimonials-highlights');

// import maps block functions
import './map';

/* Header */
require('./header');

/* Highlights */
require('./highlights');

/* Testimonials */
require('./testimonials-highlights');

/* Content grid */
require('./content_grid');

/* Page transition */
//require('./barba');

/* Function used for scrolling to certain anchor based on masthead height */
function scrollToAnchor(hash) {
    if ($(hash).length) {
        let mastheadHeight = 53;
        if (Foundation.MediaQuery.is('xlarge')) {
            mastheadHeight = 95;
        }
        $('html, body').animate({
            scrollTop: $(hash).offset().top - mastheadHeight
        }, 500);
    }
}

window.init = () => {

    $('.ac-sidebar a, footer ul a').click(function (e) {
        const linkURL = new URL(this.href);
        scrollToAnchor(linkURL.hash);
    });

    /* Foundation */
    $(document).foundation();

    /* Wow.js */
    new WOW().init();

    /** Cookie Notification **/
    let $cookieNotifications = $('.popup-notification');
    if ($cookieNotifications.length > 0) {

        $cookieNotifications.each(function () {
            let $notification = $(this),
                cookieName = $notification.attr('data-cookie-name');

            if (!getCookie(cookieName)) {
                $notification.removeClass('hide');
                setTimeout(() => {
                    $notification.addClass('fade-in');
                }, 500);
            }
        });
        $('.accept-cookies, .cookie-notification p a').on('click', function () {
            let $popup = $(this).parents('.popup-notification'),
                cookieName = $popup.attr('data-cookie-name');

            setCookie(cookieName, 1);
            $popup.addClass('fade-out');
            setTimeout(() => {
                $popup.addClass('hide');
            }, 500);
        });
    } /* End of Cookie Notification **/

    /** Standalone Contact Form **/
    $('#standalone-contact-button, #standalone-contact-popup .close-btn').on('click', function () {
        $('#standalone-contact-popup').toggleClass('expanded');
    });
    $(document).on('click', '#standalone-contact-popup .confirmation_button', function () {
        $('#standalone-contact-popup').toggleClass('expanded');
    });

    /** Static Elements Height */
    setStaticHeights();
    /** Static Elements on Scroll */
    scrollSetup();
}

/** General */
$(document).ready(function () {
    const url = new URL(window.location);
    if (url.hash) {
        scrollToAnchor(url.hash);
    }
    window.init();
});

$(window).on('load', () => {
    $(".footer").animate({
        opacity: 1,
    }, 200);
});

$(window).on('resize', function () {
    /** Static Elements Height */
    setStaticHeights();
});

$(window).on('scroll', function () {
    scrollSetup();
});

function setStaticHeights() {
    /* Footer */
    let footerFixedHeight = Foundation.MediaQuery.is('large up') ? $('.footer').outerHeight() : 0;
    $('.footer').prev().css('margin-bottom', footerFixedHeight);

    /* Hero */
    $('.block--hero--fixed').each(function () {
        let $hero = $(this),
            heroFixedHeight = $hero.find('.img-container').height();

        $hero.attr('data-height', heroFixedHeight);

        if (Foundation.MediaQuery.is('large up')) {
            $hero.find('.img-container').addClass('fixed');
            $hero.next().css('margin-top', heroFixedHeight);
        }
        else {
            $hero.find('.img-container').removeClass('fixed');
            $hero.next().css('margin-top', 0);
        }
    });
}

function scrollSetup() {
    let actualScroll = document.documentElement.scrollTop;
    let $heros = $('#blocks .block--hero--fixed');

    /* Hero */
    if ($heros.length > 0) {
        $heros.each(function () {
            let $hero = $(this),
                heroFixedHeight = Number($hero.attr('data-height')),
                heroTopPosition = $hero[ 0 ].scrollTop;
            if (actualScroll > Number(heroFixedHeight + heroTopPosition + 400)) {
                $hero.removeClass('layer-behind');
            }
            else {
                $hero.addClass('layer-behind');
            }

            if (actualScroll >= heroTopPosition && actualScroll <= (heroFixedHeight + heroTopPosition)) {
                $('.header .sticky').addClass('delayed');
            }
            else {
                $('.header .sticky.delayed').removeClass('delayed');
            }
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[ i ];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
