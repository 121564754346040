/**
 * Header JavaScript
 *
 * @package Davis Woolfe
 * @subpackage Scripts
 * @since 1.0
 * @version 1.0
 */

import Lottie from "lottie-web";

$(window).on('load', () => {
    $('#hamburgermenu-btn').on('click', function(){
        $(this).toggleClass('active');
        setTimeout(() => {
            $('#main-navigation').toggleClass('expanded');
        }, 150);
    });

    /* Main menu Functionality */
    $('.logo, #main-navigation a').on('click', function(e) {
        let $this = $(this);

        $('#main-navigation .current_page_item, #main-navigation .current_page_parent').removeClass('current_page_item current_page_parent');
        $this.parents('.menu-item').addClass('current_page_item');

        if (Foundation.MediaQuery.is('medium down')) {
            if ($this.parent().hasClass('is-dropdown-submenu-parent')) {
                $this.parent().toggleClass('custom-active');
                return false;
            }
        }
    });

    /** Init Logo animation */
    let $lottieContainer = $('#logo-lottie-player');
    setTimeout(() => {
        Lottie.loadAnimation({
            container: $lottieContainer[0], // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: true,
            width: 125,
            height: 125,
            path: $lottieContainer.attr('data-src')
        });
    }, 50);
});
