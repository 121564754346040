/**
 * Content grid Block JavaScript
 *
 * @package Davis Woolfe
 * @subpackage Scripts
 * @since 1.0
 * @version 1.0
 */

$(document).ready(() => {
    if ($('.block--cg.cg--style-2').length) {
        $('.cb_show_more').click(function (e) {
                e.preventDefault();
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active').html($(this).data('more-text'));
                }
                else {
                    $(this).addClass('active').html($(this).data('less-text'));
                }
                $(this).closest('.item-container').find('.cb_text').toggleClass('cb_text_less');
            }
        );
    }
});