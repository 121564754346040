/**
 * Highlights Block JavaScript
 *
 * @package Davis Woolfe
 * @subpackage Scripts
 * @since 1.0
 * @version 1.0
 */

window.hSlider = {};
window.hSlider.options = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 651,
    slidesToShow: 1,
    autoplay: true,
    adaptiveHeight: false,
    autoplaySpeed: 3000,
    fade: true,
};

$(document).ready(() => {
    if ($('.block--highlights--slider').length) {
        $('.block--highlights--slider .highlights-slider').not('.slick-intialized').slick(window.hSlider.options);
    }
});